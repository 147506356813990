@font-face {
    font-family: 'FS Albert Pro';
    src: url('./fonts/FSAlbertPro-Bold.eot');
    src: local('FS Albert Pro Bold'), local('FSAlbertPro-Bold'),
        url('./fonts/FSAlbertPro-Bold.eot?#iefix') format('embedded-opentype'),
        url('./fonts/FSAlbertPro-Bold.woff2') format('woff2'),
        url('./fonts/FSAlbertPro-Bold.woff') format('woff'),
        url('./fonts/FSAlbertPro-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'FS Albert Pro';
    src: url('./fonts/FSAlbertPro-Light.eot');
    src: local('FS Albert Pro Light'), local('FSAlbertPro-Light'),
        url('./fonts/FSAlbertPro-Light.eot?#iefix') format('embedded-opentype'),
        url('./fonts/FSAlbertPro-Light.woff2') format('woff2'),
        url('./fonts/FSAlbertPro-Light.woff') format('woff'),
        url('./fonts/FSAlbertPro-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'FS Albert Pro';
    src: url('./fonts/FSAlbertPro.eot');
    src: local('FS Albert Pro'), local('FSAlbertPro'),
        url('./fonts/FSAlbertPro.eot?#iefix') format('embedded-opentype'),
        url('./fonts/FSAlbertPro.woff2') format('woff2'),
        url('./fonts/FSAlbertPro.woff') format('woff'),
        url('./fonts/FSAlbertPro.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

.hide {
    display: none !important;
}

#root > div {
    width: 100%;
}

.dashboard > div {
    width: 100%;
}
.bg-light-gray {
    background-color: #fafafa;
}
.documents-items {
    background-color: transparent !important;
}
thead tr th:nth-child(1),
tbody tr td:nth-child(1) {
    padding-left: 30px;
}